// Auth Login
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_START_GOOGLE_LOGIN = 'AUTH_START_GOOGLE_LOGIN';
export const AUTH_START_EMAIL_LOGIN = 'AUTH_START_EMAIL_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGIN_LOGGED_USER = 'AUTH_LOGIN_LOGGED_USER';

// Auth Signup
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';

// Locale
export const LOCALE_SET = 'LOCALE_SET';
export const LOCALE_SET_SUCCESS = 'LOCALE_SET_SUCCESS';

// Reporting
export const REPORT_CHANGE_QUESTION = 'REPORT_CHANGE_QUESTION';
export const REPORT_POST = 'REPORT_POST';
export const REPORT_GET = 'REPORT_GET';
export const REPORT_DELETE = 'REPORT_DELETE';

// Find Notification
export const FIND_NOTIFICATION_SET_DATE = 'FIND_NOTIFICATION_SET_DATE';
export const FIND_NOTIFICATION_SET_COORDS = 'FIND_NOTIFICATION_SET_COORDS';
export const FIND_NOTIFICATION_SET_FIND_PHOTOS = 'FIND_NOTIFICATION_SET_FIND_PHOTOS';
export const FIND_NOTIFICATION_SET_FIND_PHOTOS_SUCCESS = 'FIND_NOTIFICATION_SET_FIND_PHOTOS_SUCCESS';
export const FIND_NOTIFICATION_SET_FIND_SITE_PHOTOS = 'FIND_NOTIFICATION_SET_FIND_SITE_PHOTOS';
export const FIND_NOTIFICATION_SET_FIND_SITE_PHOTOS_SUCCESS = 'FIND_NOTIFICATION_SET_FIND_SITE_PHOTOS_SUCCESS';
export const FIND_NOTIFICATION_SET_FIND_ADDITIONAL_MATERIALS = 'FIND_NOTIFICATION_SET_FIND_ADDITIONAL_MATERIALS';
export const FIND_NOTIFICATION_CHANGE_FIND_INDEX = 'FIND_NOTIFICATION_CHANGE_FIND_INDEX';
export const FIND_NOTIFICATION_SET_FIND_TYPE = 'FIND_NOTIFICATION_SET_FIND_TYPE';
export const FIND_NOTIFICATION_SET_FIND_MATERIAL = 'FIND_NOTIFICATION_SET_FIND_MATERIAL';
export const FIND_NOTIFICATION_SET_FIND_TIMING = 'FIND_NOTIFICATION_SET_FIND_TIMING';
export const FIND_NOTIFICATION_SET_FIND_DEPTH = 'FIND_NOTIFICATION_SET_FIND_DEPTH';
export const FIND_NOTIFICATION_SET_MUNICIPALITY = 'FIND_NOTIFICATION_SET_MUNICIPALITY';
export const FIND_NOTIFICATION_SET_MUNICIPALITY_SUCCESS = 'FIND_NOTIFICATION_SET_MUNICIPALITY_SUCCESS';
export const FIND_NOTIFICATION_SEND = 'FIND_NOTIFICATION_SEND';
export const FIND_NOTIFICATION_SEND_SUCCESS = 'FIND_NOTIFICATION_SEND_SUCCESS';
export const FIND_NOTIFICATION_DELETION_SUCCESS = 'FIND_NOTIFICATION_DELETION_SUCCESS';
export const FIND_NOTIFICATION_SEND_FAIL = 'FIND_NOTIFICATION_SEND_FAIL';
export const FIND_NOTIFICATION_SET_STATUS_TO_AWAIT_REVIEW = 'FIND_NOTIFICATION_SET_STATUS_TO_AWAIT_REVIEW';
export const FIND_NOTIFICATION_RESET = 'FIND_NOTIFICATION_RESET';
export const FIND_NOTIFICATION_SKIP_HELP_TUTORIAL_STEPS = 'FIND_NOTIFICATION_SKIP_HELP_TUTORIAL_STEPS';
export const FIND_NOTIFICATION_SET_NEARBY_SMART_HELP = 'FIND_NOTIFICATION_SET_NEARBY_SMART_HELP';
export const FIND_NOTIFICATION_SET_PROPERTY_SMART_HELP = 'FIND_NOTIFICATION_SET_PROPERTY_SMART_HELP';
export const FIND_NOTIFICATION_SET_PROPERTY_SMART_HELP_SUCCESS = 'FIND_NOTIFICATION_SET_PROPERTY_SMART_HELP_SUCCESS';
export const FIND_NOTIFICATION_SET_REPORT_ID = 'FIND_NOTIFICATION_SET_REPORT_ID';
export const FIND_NOTIFICATION_DELETE_PHOTO = 'FIND_NOTIFICATION_DELETE_PHOTO';
export const FIND_NOTIFICATION_DELETE_PHOTO_SUCCESS = 'FIND_NOTIFICATION_DELETE_PHOTO_SUCCESS';
export const FIND_NOTIFICATION_DELETE_PHOTO_FAIL = 'FIND_NOTIFICATION_DELETE_PHOTO_FAIL';
export const FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA = 'FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA';
export const FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_FETCHING = 'FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_FETCHING';
export const FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_FETCHING_FINISHED = 'FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_FETCHING_FINISHED';
export const FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_SUCCESS = 'FIND_NOTIFICATION_GET_AUTOCOMPLETE_DATA_SUCCESS';

// Find
export const FIND_GET_VALIDATED_FINDS = 'FIND_GET_VALIDATED_FINDS';
export const FIND_GET_VALIDATED_SUCCESS = 'FIND_GET_VALIDATED_SUCCESS';
export const FIND_GET_VALIDATED_FAIL = 'FIND_GET_VALIDATED_FAIL';
export const FIND_GET_VALIDATED_FIND = 'FIND_GET_VALIDATED_FIND';
export const FIND_GET_VALIDATED_FIND_SUCCESS = 'FIND_GET_VALIDATED_FIND_SUCCESS';
export const FIND_GET_VALIDATED_FIND_FAIL = 'FIND_GET_VALIDATED_FIND_FAIL';

// My Finds
export const MY_FINDS_GET_REPORTS = 'MY_FINDS_GET_REPORTS';
export const MY_FINDS_GET_REPORTS_SUCCESS = 'MY_FINDS_GET_REPORTS_SUCCESS';
export const MY_FINDS_GET_REPORTS_FAIL = 'MY_FINDS_GET_REPORTS_FAIL';
export const MY_FINDS_GET_CERTAIN_FINDS = 'MY_FINDS_GET_CERTAIN_FINDS';
export const MY_FINDS_GET_CERTAIN_FINDS_SUCESS = 'MY_FINDS_GET_CERTAIN_FINDS_SUCESS';
export const MY_FINDS_GET_CERTAIN_FINDS_FAIL = 'MY_FINDS_GET_CERTAIN_FINDS_FAIL';
export const MY_FINDS_CONTINUE_FILLING_OUT = 'MY_FINDS_CONTINUE_FILLING_OUT';
export const MY_FINDS_ORDER_REPORTS = 'MY_FINDS_ORDER_REPORTS';
export const MY_FINDS_GET_MY_REPORTS_FINDS = 'MY_FINDS_GET_MY_REPORTS_FINDS';
export const MY_FINDS_GET_MY_REPORTS_FINDS_SUCCESS = 'MY_FINDS_GET_MY_REPORTS_FINDS_SUCCESS';


// Facet Filters
export const FACET_FILTER_SET = 'FACET_FILTER_SET';
export const FACET_FILTER_REMOVE = 'FACET_FILTER_REMOVE';
export const FACET_FILTER_EMPTY = 'FACET_FILTER_EMPTY';
export const FACET_FILTER_PANEL_SET = 'FACET_FILTER_PANEL_SET';
export const FACET_FILTER_PANEL_REMOVE = 'FACET_FILTER_PANEL_REMOVE';

// Notifier
export const NOTIFIER_ENQUEUE_SNACKBAR = 'NOTIFIER_ENQUEUE_SNACKBAR';
export const NOTIFIER_REMOVE_SNACKBAR = 'NOTIFIER_REMOVE_SNACKBAR';
export const NOTIFIER_CHANGE_STATUS = 'NOTIFIER_CHANGE_STATUS';

// MAP
export const MAP_FETCH_DATA = 'MAP_FETCH_DATA';
export const MAP_FETCH_DATA_SUCCESS = 'MAP_FETCH_DATA_SUCCESS';
export const MAP_FETCH_DATA_FAIL = 'MAP_FETCH_DATA_FAIL';
export const MAP_START_SPINNER = 'MAP_START_SPINNER';